export const stringifyQueryParams = (
  obj: Record<string, unknown>
): Record<string, string | string[]> => {
  const result: Record<string, string | string[]> = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (value === null || typeof value === 'undefined') {
      return;
    }

    if (Array.isArray(value)) {
      result[key] = value.map((item) => String(item));
    } else {
      result[key] = String(value);
    }
  });

  return result;
};
