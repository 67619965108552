import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { query } from 'src/utils/query';

import { stringifyQueryParams } from './stringifyQueryParams';

interface Opts {
  replace?: boolean;
  state?: unknown;
}

export const useQueryParams = (
  key: string
): [string | null, (value: string | undefined, options?: Opts) => void] => {
  const [params, setParams] = useSearchParams();
  const setCurrentTab = useCallback(
    (value: string | undefined, options?: Opts): void => {
      const queryParams = query.parse(params.toString());
      if (!value) {
        delete queryParams[key];
      }
      setParams(
        stringifyQueryParams({ ...queryParams, [key]: value }),
        options
      );
    },
    [params]
  );

  const result = params.get(key);

  return [result, setCurrentTab];
};
